<template>
  <div id="reviews" ref="reviews" class="reviews">
    <div class="u-margin-bottom--1">
      <slot name="title" data-cy="review-title" />
      <ReviewAggregate
        :average-rating="averageRating"
        :reviews-count="reviewsCount"
      />
    </div>
    <div class="l-grid">
      <div
        v-if="countPerRating"
        class="l-grid__cell l-grid__cell--12-col l-grid__cell--4-col-desktop"
      >
        <ReviewFilters
          :filters="filters"
          :rating-counts="countPerRating"
          @update="filterAction"
        ></ReviewFilters>
      </div>
      <div
        class="l-grid__cell l-grid__cell--12-col l-grid__cell--8-col-desktop"
      >
        <div
          v-if="assignReviews && assignReviews.length > 0"
          class="reviews__wrapper"
        >
          <Review
            v-for="(review, key) in assignReviews"
            :key="key"
            :title="review.title"
            :star-rating="review.rating"
            :reviewer="review.firstName"
            :traveled-date="new Date(review.departureStartDate)"
            :comment="review.comment"
            :created-date="new Date(review.submissionDate)"
          ></Review>
          <div v-if="showSpinner" class="reviews__loader">
            <Spinner
              :show="showSpinner"
              aria-label="Loading"
              class="reviews__loader__icon"
            />
          </div>
        </div>
        <div
          v-if="assignReviews && assignReviews.length > 0"
          class="reviews__footer"
        >
          <div class="reviews__footer-paginator">
            <Pagination
              :page="currentPage"
              :total-pages="totalPages"
              @previous="paginationPrevious"
              @next="paginationNext"
            ></Pagination>
          </div>
          <Button
            :href="$link.create('reviews')"
            class="button--secondary button--large"
            data-cy="reviews-link-button"
          >
            Explore reviews for all trips
          </Button>
        </div>
        <h6
          v-if="!assignReviews || (assignReviews && assignReviews.length === 0)"
          class="headline--6"
          data-cy="reviews__footer-no-reviews-title"
        >
          No reviews found
        </h6>
        <p v-if="!assignReviews">Try clearing or changing some filters.</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Spinner from "atlas/src/components/Spinner/Spinner.vue";
import Review from "atlas/src/components/Review/Review.vue";
import Pagination from "atlas/src/components/Pagination/Pagination.vue";
import { ReviewsProps, ReviewCommentsProps } from "./Props";
import { ReviewAggregateProps } from "~/components/ReviewAggregate/Props";
import ReviewFilters from "~/components/ReviewFilters/ReviewFilters.vue";
import ReviewAggregate from "~/components/ReviewAggregate/ReviewAggregate.vue";
import { fetchParser } from "~~/lib/utils/datetime/dateTimeReviver";
import { ReviewSettings } from "~~/lib/types/Hayabusa/Review";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";
import { isLivePreviewEnabled } from "~~/lib/utils/contentstack/clientOptionDecorator";

const logger = loggerFactory({
  tags: [logTags.Layer.Component],
});

export default Vue.extend({
  name: "Reviews",
  components: {
    Button,
    Spinner,
    Pagination,
    ReviewFilters,
    Review,
    ReviewAggregate,
  },
  props: {
    averageRating: {
      type: Number as PropType<ReviewAggregateProps["averageRating"]>,
      required: true,
    },
    reviewsCount: {
      type: Number as PropType<ReviewAggregateProps["reviewsCount"]>,
      required: true,
    },
    countPerRating: {
      type: Object as PropType<ReviewAggregateProps["countPerRating"]>,
      required: false,
      default: undefined,
    },
    reviews: {
      type: Object as PropType<ReviewsProps>,
      required: true,
    },
    month: {
      type: String as PropType<ReviewsProps["month"]>,
      required: false,
      default: undefined,
    },
  },
  data(): {
    showSpinner: boolean;
    currentPage: number;
    filters: {
      rating: number[];
    };
    refreshReviews: boolean;
    initialLoad: boolean;
    clientReviewComments: ReviewCommentsProps[];
  } {
    return {
      showSpinner: false,
      currentPage: 1,
      filters: {
        rating: [],
      },
      refreshReviews: false,
      initialLoad: true,
      clientReviewComments: [],
    };
  },
  computed: {
    assignReviews(): ReviewCommentsProps[] {
      if (this.refreshReviews && !(this.showSpinner && this.initialLoad)) {
        return this.clientReviewComments;
      }

      return this.reviews.reviewComments;
    },
    totalPages() {
      const limit = 5;
      const totalRecords =
        this.filters?.rating.reduce(
          (sum: number, rating: number) =>
            sum + parseFloat((this.countPerRating?.[rating] || "0") as string),
          0
        ) || this.reviewsCount;

      return Math.ceil(totalRecords / limit);
    },
  },
  mounted() {
    if (
      this.reviews &&
      this.reviews.reviewComments &&
      this.reviews.reviewComments.length > 0
    ) {
      this.$store
        .dispatch("app/componentUpdate")
        .catch((err) =>
          logger.error("An exception in dispatching from store", { error: err })
        );
    }
  },
  methods: {
    setFocus() {
      if (this.$refs.reviews) {
        (this.$refs.reviews as Element).scrollIntoView();
      }
    },
    paginationPrevious() {
      --this.currentPage;
      this.fetchReviews().catch((err) =>
        logger.error("Error while fetching reviews in paginationPrevious", {
          error: err,
        })
      );
      this.setFocus();
    },
    paginationNext() {
      ++this.currentPage;
      this.fetchReviews().catch((err) =>
        logger.error("Error while fetching reviews in paginationNext", {
          error: err,
        })
      );
      this.setFocus();
    },
    filterAction() {
      this.currentPage = 1;
      this.fetchReviews().catch((err) =>
        logger.error("Error while fetching reviews in filterAction", {
          error: err,
        })
      );
      this.setFocus();
    },
    async fetchReviews() {
      this.showSpinner = true;
      this.refreshReviews = true;
      const limit = 5;
      const result = await $fetch<ReviewsProps>("/api/nuxt/review", {
        params: {
          localeCode: this.reviews.localeCode,
          currencyCode: this.reviews.currencyCode,
          displayName: this.reviews.displayName,
          isLivePreview: isLivePreviewEnabled({
            livePreviewHash: this.reviews.livePreviewHash,
            contentTypeUid: this.reviews.contentTypeUid,
          }).toString(),
          limit,
          page: this.currentPage ? this.currentPage : 1,
          ratingFilter:
            this.filters && this.filters.rating.length > 0
              ? this.filters.rating.toString()
              : ReviewSettings.filterRating.toString(),
          productCodes: JSON.stringify(this.reviews.productCodes),
          pageType: this.reviews.pageType ?? this.reviews.contentTypeUid,
          destinationType:
            this.reviews.destinationType !== undefined &&
            this.reviews.destinationType.toLocaleLowerCase(),
          productFilterParams: this.reviews.productFilterParams,
          month: this.month && this.month,
        },
        parseResponse: fetchParser,
      });

      if (result.reviewComments) {
        this.clientReviewComments = result.reviewComments;
        this.showSpinner = false;
      }
      this.initialLoad = false;
    },
  },
});
</script>

<style lang="scss">
@import "./reviews";
</style>
