<template>
  <div v-if="images" data-cy="media-panel">
    <Imagery
      v-if="images.length === 1"
      :lazy-src="images[0].src"
      :lazy-srcset="images[0].srcSet"
      :alt="images[0].alt"
      data-cy="media-panel__imagery"
    ></Imagery>
    <Gallery v-else :images="images" :lazyLoad="true"></Gallery>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import Gallery from "../Gallery/Gallery.vue";
import { MediaPanelProps } from "./Props";

export default Vue.extend({
  name: "MediaPanel",
  components: {
    Imagery,
    Gallery,
  },
  props: {
    images: {
      type: Array as PropType<MediaPanelProps["images"]>,
      required: true,
    },
  },
});
</script>
