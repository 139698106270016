<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div class="rich-text" v-html="sanitizedHTML" />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import VueSanitize from "vue-sanitize";
import { RichTextProps } from "./Type";

Vue.use(VueSanitize);

export default Vue.extend({
  name: "RichText",
  props: {
    html: {
      type: String as PropType<RichTextProps["html"]>,
      required: true,
    },
  },
  computed: {
    sanitizedHTML(): string {
      return this.html ? this.$sanitize(this.html) : "";
    },
  },
});
</script>
<style lang="scss">
@import "rich-text";
</style>
