<template>
  <TripList v-if="trips.length > 0" :trips="trips" />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import TripList from "../TripList/TripList.vue";
import { ProductReferencePanelProps } from "./props";

export default Vue.extend({
  name: "ProductReferencePanel",
  components: { TripList },
  props: {
    trips: {
      type: Array as PropType<ProductReferencePanelProps["trips"]>,
      default() {
        return [];
      },
      required: false,
    },
  },
});
</script>
