<template>
  <div data-cy="profile-section">
    <h2 data-cy="profile-section__title">{{ title }}</h2>
    <p data-cy="profile-section__description">{{ description }}</p>
    <ProfileList
      :profiles="profiles"
      class="u-margin-top--2 sm:u-margin-top--1-5"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { ProfileSectionProps } from "./Props";
import ProfileList from "~/components/ProfileList/ProfileList.vue";

export default Vue.extend({
  name: "ProfileSection",
  components: {
    ProfileList,
  },
  props: {
    title: {
      type: String as PropType<ProfileSectionProps["title"]>,
      required: true,
    },
    description: {
      type: String as PropType<ProfileSectionProps["description"]>,
      required: true,
    },
    profiles: {
      type: Array as PropType<ProfileSectionProps["profiles"]>,
      required: true,
    },
  },
});
</script>
