<template>
  <ProfileCardAtlas
    :firstName="firstName"
    :lastName="lastName"
    :role="role"
    :description="description"
    :profilePicture="profilePicture"
    data-cy="profile-card"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import ProfileCardAtlas from "atlas/src/components/ProfileCard/ProfileCard.vue";
import { ProfileCardProps } from "./Props";

export default Vue.extend({
  name: "ProfileCard",
  components: {
    ProfileCardAtlas,
  },
  props: {
    profilePicture: {
      type: Object as PropType<ProfileCardProps["profilePicture"]>,
      required: false,
      default: undefined,
    },
    firstName: {
      type: String as PropType<ProfileCardProps["firstName"]>,
      required: true,
      default: undefined,
    },
    lastName: {
      type: String as PropType<ProfileCardProps["lastName"]>,
      required: false,
      default: undefined,
    },
    role: {
      type: String as PropType<ProfileCardProps["role"]>,
      required: true,
      default: undefined,
    },
    description: {
      type: String as PropType<ProfileCardProps["description"]>,
      required: true,
      default: undefined,
    },
  },
});
</script>
