<template>
  <div class="u-margin-top--1 u-margin-bottom--1" data-cy="rating-filter">
    <RatingFilterItem
      v-for="rating in ratings"
      :key="rating"
      v-model="inputValue"
      :percentage="percentage(rating)"
      :rating="rating"
      :rating-count="ratingCount(rating)"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { RatingFilterProps } from "./Props";
import RatingFilterItem from "./RatingFilterItem.vue";

export default Vue.extend({
  name: "RatingFilter",
  components: {
    RatingFilterItem,
  },
  props: {
    ratingCounts: {
      type: Object as PropType<RatingFilterProps["ratingCounts"]>,
      required: true,
    },
    value: {
      type: Array as PropType<RatingFilterProps["value"]>,
      required: true,
    },
  },
  data() {
    return {
      ratings: [5, 4, 3, 2, 1],
    };
  },
  computed: {
    inputValue: {
      get(): RatingFilterProps["value"] {
        return this.value;
      },
      set(value: RatingFilterProps["value"]) {
        this.$emit("input", value);
      },
    },
    totalRatings(): number {
      let total = 0;

      this.ratings.forEach((rating) => {
        if (this.ratingCounts[rating]) {
          total += this.ratingCounts[rating];
        }
      });

      return total;
    },
  },
  methods: {
    ratingCount(rating: number): number {
      if (this.ratingCounts[rating]) {
        return this.ratingCounts[rating];
      }

      return 0;
    },
    percentage(rating: number): number {
      if (this.ratingCounts[rating]) {
        return (this.ratingCounts[rating] / this.totalRatings) * 100;
      }

      return 0;
    },
  },
});
</script>
