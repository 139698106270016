<template>
  <div class="rating-filter-item" data-cy="rating-filter-item">
    <div class="rating-filter-item__checkbox">
      <!-- @vue-ignore -->
      <Checkbox
        :id="rating + 'star-checkbox'"
        v-model="inputValue"
        :value="rating"
        data-cy="rating-filter-item-checkbox"
        class="u-margin-bottom--0 u-margin-top--0 u-margin-right--0"
      >
        <span class="rating-filter-item__checkbox__label">
          {{ checkboxLabel }}
        </span>
      </Checkbox>
    </div>
    <div class="rating-filter-item__progress">
      <ProgressBar :percentage="percentage" :label="progressLabel" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import ProgressBar from "atlas/src/components/ProgressBar/ProgressBar.vue";
import Checkbox from "atlas/src/components/Checkbox/Checkbox.vue";
import { RatingFilterItemProps } from "./Props";

export default Vue.extend({
  name: "RatingFilterItem",
  components: {
    Checkbox,
    ProgressBar,
  },
  props: {
    percentage: {
      type: Number as PropType<RatingFilterItemProps["percentage"]>,
      required: true,
      default: 0,
    },
    rating: {
      type: Number as PropType<RatingFilterItemProps["rating"]>,
      required: true,
    },
    ratingCount: {
      type: Number as PropType<RatingFilterItemProps["ratingCount"]>,
      required: false,
      default: 0,
    },
    value: {
      type: Array as PropType<RatingFilterItemProps["value"]>,
      required: true,
    },
  },
  computed: {
    checkboxLabel(): string {
      return `${this.rating} Star`;
    },
    progressLabel(): string {
      return this.ratingCount.toString();
    },
    inputValue: {
      get(): RatingFilterItemProps["value"] {
        return this.value;
      },
      set(value: RatingFilterItemProps["value"]) {
        this.$emit("input", value);
      },
    },
  },
});
</script>

<style lang="scss">
@import "./rating-filter-item";
</style>
