<template>
  <div data-cy="profile-list">
    <div v-if="useCarousel" class="l-grid">
      <div
        v-for="(profile, index) of profiles"
        :key="index"
        class="l-grid__cell l-grid__cell--6-col"
      >
        <ProfileCard
          v-bind="profile"
          data-cy="profile-list__single"
          class="u-height--full"
        />
      </div>
    </div>
    <CarouselResponsive
      v-else
      description="Profiles carousel"
      :items-xs="1"
      :items-sm="2"
      :items-md="3"
      :items-lg="3"
      :items-xl="3"
    >
      <CarouselResponsiveItem v-for="(profile, index) of profiles" :key="index">
        <ProfileCard
          v-bind="profile"
          :profilePicture="profile.profilePicture"
          data-cy="profile-list__multiple"
          class="u-height--full"
        />
      </CarouselResponsiveItem>
    </CarouselResponsive>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import CarouselResponsive from "atlas/src/components/CarouselResponsive/CarouselResponsive.vue";
import CarouselResponsiveItem from "atlas/src/components/CarouselResponsive/CarouselResponsiveItem.vue";
import { ProfileCardProps } from "~/components/ProfileCard/Props";
import ProfileCard from "~/components/ProfileCard/ProfileCard.vue";

export default Vue.extend({
  name: "ProfileList",
  components: {
    ProfileCard,
    CarouselResponsive,
    CarouselResponsiveItem,
  },
  props: {
    profiles: {
      type: Array as PropType<ProfileCardProps[]>,
      required: true,
    },
  },
  data() {
    return {
      carouselBreakValue: 2,
    };
  },
  computed: {
    useCarousel(): boolean {
      return this.profiles.length < this.carouselBreakValue;
    },
  },
});
</script>
