<template>
  <Card :image="media" data-cy="room-option-card">
    <template slot="heading">
      <strong class="u-display--block u-margin-bottom--1">
        {{ displayName }}
      </strong>
    </template>
    <template slot="content">
      <ul class="u-padding-left--1 u-margin-top--0">
        <li v-for="(feature, index) in features" :key="index">
          {{ feature }}
        </li>
      </ul>
    </template>
  </Card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Card from "atlas/src/components/Card/Card.vue";
import { RoomOptionCardProps } from "./Props";

export default Vue.extend({
  name: "RoomOptionCard",
  components: {
    Card,
  },
  props: {
    displayName: {
      type: String as PropType<RoomOptionCardProps["displayName"]>,
      required: true,
      default: "",
    },
    media: {
      type: Object as PropType<RoomOptionCardProps["media"]>,
      required: false,
      default: null,
    },
    features: {
      type: Array as PropType<RoomOptionCardProps["features"]>,
      required: false,
      default: null,
    },
  },
});
</script>
