<template>
  <div class="panels" data-cy="panels">
    <ErrorBoundary
      :is="panel.type"
      v-bind="panel.props"
      v-for="(panel, index) in panels"
      :key="index"
      :class="getPanelClass(panel)"
    >
    </ErrorBoundary>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import JsonRichText from "../JsonRichText/JsonRichText.vue";
import CallToActionPanel from "../CallToActionPanel/CallToActionPanel.vue";
import SectionBreakPanel from "../SectionBreakPanel/SectionBreakPanel.vue";
import FormPanel from "../FormPanel/FormPanel.vue";
import ContentPanel from "../ContentPanel/ContentPanel.vue";
import UniqueSellingPointPanel from "../UniqueSellingPointPanel/UniqueSellingPointPanel.vue";
import ProductReferencePanel from "../ProductReferencePanel/ProductReferencePanel.vue";
import { PanelsProps, PanelType } from "./Type";
import ErrorBoundary from "~/components/ErrorBoundary/ErrorBoundary.vue";
import HeadingPanel from "~/components/HeadingPanel/HeadingPanel.vue";
import MediaPanel from "~/components/MediaPanel/MediaPanel.vue";
import BlogPanel from "~/components/BlogPanel/BlogPanel.vue";
import TripPanel from "~/components/TripPanel/TripPanel.vue";
import ReviewPanel from "~/components/ReviewPanel/ReviewPanel.vue";
import ReviewReferencePanel from "~/components/ReviewReferencePanel/ReviewReferencePanel.vue";
import VideoPanel from "~/components/VideoPanel/VideoPanel.vue";
import FAQsPanel from "~/components/FAQsPanel/FAQsPanel.vue";

export default Vue.extend({
  name: "Panels",
  components: {
    JsonRichText,
    CallToActionPanel,
    FormPanel,
    ContentPanel,
    UniqueSellingPointPanel,
    ErrorBoundary,
    HeadingPanel,
    TripPanel,
    SectionBreakPanel,
    ProductReferencePanel,
    MediaPanel,
    ReviewPanel,
    ReviewReferencePanel,
    BlogPanel,
    VideoPanel,
    FAQsPanel,
  },
  props: {
    panels: {
      type: Array as PropType<PanelsProps["panels"]>,
      default: () => [],
    },
  },
  methods: {
    getPanelClass(panel: PanelType): string {
      if (panel.type === "SectionBreakPanel" || panel.type === "HeadingPanel") {
        return "";
      }
      return "u-margin-bottom--2";
    },
  },
});
</script>
