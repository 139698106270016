<template>
  <div class="l-container u-margin-top--2">
    <template v-for="(error, index) of errors">
      <Alert :key="index" type="danger">
        <div slot="content">
          {{ error }}
        </div>
      </Alert>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Alert from "atlas/src/components/Alert/Alert.vue";

export default Vue.extend({
  name: "LivePreviewErrors",
  components: {
    Alert,
  },
  props: {
    errors: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
});
</script>
