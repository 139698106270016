<template>
  <TileContent :icon="icon" :title="title" data-cy="ittrfy">
    <template #content>
      <RichText
        v-if="description"
        :html="description"
        data-cy="ittrfy__description"
      />
    </template>
  </TileContent>
</template>

<script lang="ts">
import TileContent from "atlas/src/components/TileContent/TileContent.vue";
import Vue, { PropType } from "vue";
import { ITTRFYProps } from "./Props";
import RichText from "~/components/RichText/RichText.vue";

export default Vue.extend({
  name: "ITTRFY",
  components: {
    TileContent,
    RichText,
  },
  props: {
    description: {
      type: String as PropType<ITTRFYProps["description"]>,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      icon: "right-trip-map",
      title: "Is this trip right for you?",
    };
  },
});
</script>
