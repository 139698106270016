<template>
  <div data-cy="route">
    <h2>
      {{ displayName }}
    </h2>
    <div class="route__wrapper">
      <Imagery
        v-if="image?.src && image?.srcSet"
        :lazy-src="image.src"
        :lazy-srcset="image.srcSet"
        :width="image.width"
        :height="image.height"
        :alt="image.alt"
        class="route__image"
      />

      <div v-if="options && options.length" class="l-grid">
        <RouteOption
          v-for="option in options"
          :key="option.title"
          v-bind="option"
          class="l-grid__cell"
          :class="computedClass"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import RouteOption from "./RouteOption.vue";
import { RouteProps } from "./Props";

export default Vue.extend({
  name: "Route",
  components: {
    Imagery,
    RouteOption,
  },
  props: {
    displayName: {
      type: String as PropType<RouteProps["displayName"]>,
      required: true,
    },
    image: {
      type: Object as PropType<RouteProps["image"]>,
      required: true,
    },
    options: {
      type: Array as PropType<RouteProps["options"]>,
      required: true,
    },
  },
  computed: {
    computedClass() {
      const count = this.options?.length;
      if (count === 1) return "l-grid__cell--12-col-desktop";
      if (count === 2) return "l-grid__cell--6-col-desktop";
      if (count === 3)
        return "l-grid__cell--8-col-tablet l-grid__cell--4-col-desktop";

      return "l-grid__cell--4-col-tablet";
    },
  },
});
</script>
<style lang="scss">
@import "./route";
</style>
