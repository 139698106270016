<template>
  <Reviews
    v-if="reviews?.reviewComments?.length"
    v-bind="reviewAggregate"
    :reviews="reviews"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Reviews from "../Reviews/Reviews.vue";
import { ReviewReferencePanelProps } from "./Props";

export default Vue.extend({
  name: "ReviewReferencePanel",
  components: { Reviews },

  props: {
    reviewAggregate: {
      type: Object as PropType<ReviewReferencePanelProps["reviewAggregate"]>,
      required: false,
      default: undefined,
    },
    reviews: {
      type: Object as PropType<ReviewReferencePanelProps["reviews"]>,
      required: false,
      default: undefined,
    },
  },
});
</script>
