<template>
  <div class="" data-cy="route__option">
    <Card class="u-height--full">
      <template slot="content">
        <div
          class="
            u-height--full
            u-display--flex
            u-flex-direction--column
            u-justify-content--space-between
          "
        >
          <div>
            <div class="headline--5 u-margin-top--0 u-margin-bottom--0-5">
              {{ title }}
            </div>
            <JsonRichText v-bind="description" />
          </div>
          <Button
            v-if="link"
            :href="$link.create(link)"
            class="button--secondary button--block"
          >
            Explore route
          </Button>
        </div>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Card from "atlas/src/components/Card/Card.vue";
import { RouteOptionProps } from "./Props";
import JsonRichText from "~/components/JsonRichText/JsonRichText.vue";

export default Vue.extend({
  name: "RouteOption",
  components: { JsonRichText, Button, Card },
  props: {
    title: {
      type: String as PropType<RouteOptionProps["title"]>,
      required: true,
    },
    description: {
      type: Object as PropType<RouteOptionProps["description"]>,
      required: true,
    },
    link: {
      type: String as PropType<RouteOptionProps["link"]>,
      default: "",
    },
  },
});
</script>
