<template>
  <div class="product-point">
    <h4
      v-if="heading && (address || name || phone || fax || details)"
      class="headline--5"
    >
      {{ heading }}
    </h4>
    <RichText v-if="details" :html="details" />
    <p
      v-if="address || name || phone || fax"
      data-cy="product-point-contact-details"
    >
      {{ name }}<br v-if="name" />
      <span v-if="address" class="product-point__address"
        >{{ address }}<br
      /></span>
      <span v-if="phone">Phone: {{ phone }}<br /></span>
      <span v-if="fax">Fax: {{ fax }}<br /></span>
    </p>
    <h4
      v-if="description"
      :id="buildHeadlineId(heading, pointType, 'description')"
      class="headline--5 eti-page__section-heading"
      :data-anchor-text="
        heading ? heading + ' description' : pointType + ' description'
      "
    >
      Description
    </h4>
    <RichText v-if="description" :html="description" />
    <h4
      v-if="instructions"
      :id="buildHeadlineId(heading, pointType, 'instructions')"
      class="headline--5 eti-page__section-heading"
      :data-anchor-text="
        heading ? heading + ' instructions' : pointType + ' instructions'
      "
    >
      Instructions
    </h4>
    <RichText v-if="instructions" :html="instructions" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import RichText from "../RichText/RichText.vue";
import { ProductPointProps } from "./Props";
export default Vue.extend({
  name: "ProductPoint",
  components: {
    RichText,
  },
  props: {
    pointType: {
      type: String,
      required: false,
      default: undefined,
    },
    heading: {
      type: String,
      required: false,
      default: undefined,
    },
    name: {
      type: String as PropType<ProductPointProps["name"]>,
      required: false,
      default: undefined,
    },
    phone: {
      type: String as PropType<ProductPointProps["phone"]>,
      required: false,
      default: undefined,
    },
    fax: {
      type: String as PropType<ProductPointProps["fax"]>,
      required: false,
      default() {
        return "";
      },
    },
    address: {
      type: String as PropType<ProductPointProps["address"]>,
      required: false,
      default: undefined,
    },
    details: {
      type: String as PropType<ProductPointProps["details"]>,
      required: false,
      default: undefined,
    },
    description: {
      type: String as PropType<ProductPointProps["description"]>,
      required: false,
      default: undefined,
    },
    instructions: {
      type: String as PropType<ProductPointProps["instructions"]>,
      required: false,
      default: undefined,
    },
  },
  methods: {
    buildHeadlineId(heading?: string, type?: string, suffix?: string): string {
      const name = heading ?? type;
      return ("child " + name?.toLowerCase() + " " + suffix).replace(
        /\s+/g,
        "-"
      );
    },
  },
});
</script>

<style lang="scss">
@import "./product-point";
</style>
