<template>
  <div>
    <p class="headline--6 review-filters__title">Filter by rating</p>

    <RatingFilter
      v-model="ratingsModel"
      :rating-counts="ratingCounts"
      data-cy="rating-filter"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { ReviewFiltersProps } from "./Props";
import RatingFilter from "~/components/RatingFilter/RatingFilter.vue";

type Data = {
  mutableFilters: ReviewFiltersProps["filters"];
};

export default Vue.extend({
  name: "ReviewFilters",
  components: {
    RatingFilter,
  },
  props: {
    filters: {
      type: Object as PropType<ReviewFiltersProps["filters"]>,
      required: true,
    },
    ratingCounts: {
      type: Object as PropType<ReviewFiltersProps["ratingCounts"]>,
      required: true,
    },
  },
  data(): Data {
    return {
      mutableFilters: this.filters,
    };
  },
  computed: {
    ratingsModel: {
      get(): ReviewFiltersProps["filters"]["rating"] {
        return this.mutableFilters.rating;
      },
      set(value: ReviewFiltersProps["filters"]["rating"]) {
        this.mutableFilters.rating = value;
        this.emitUpdate();
      },
    },
  },
  methods: {
    emitUpdate() {
      this.$emit("update", true);
    },
  },
});
</script>

<style lang="scss">
@import "./review-filters";
</style>
