<template>
  <div>
    <Reviews
      v-if="reviews?.reviewComments?.length"
      v-bind="reviewAggregate"
      :reviews="reviews"
    />
    <Tile
      v-else
      :title="noReviewsTileTitle"
      :icon="noReviewTileIcon"
      class="tile tile--x-large"
    >
      <p class="u-margin-bottom--0">
        Check back later or
        <Link :href="allReviewsPagePath">
          explore reviews for similar trips.
        </Link>
      </p>
    </Tile>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import Reviews from "../Reviews/Reviews.vue";
import Link from "../Link/Link.vue";
import { ReviewPanelProps } from "./Props";

export default Vue.extend({
  name: "ReviewPanel",
  components: { Reviews, Tile, Link },

  props: {
    reviewAggregate: {
      type: Object as PropType<ReviewPanelProps["reviewAggregate"]>,
      required: false,
      default: undefined,
    },
    reviews: {
      type: Object as PropType<ReviewPanelProps["reviews"]>,
      required: false,
      default: undefined,
    },
  },

  data() {
    return {
      noReviewsTileTitle:
        "Looks like this trip is a best-kept secret... for now!",
      noReviewTileIcon: "reviews",
      allReviewsPagePath: "/reviews",
    };
  },
});
</script>
