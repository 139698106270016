<template>
  <div data-cy="review-aggregate" class="review-aggregate sm:u-display--flex">
    <Rating :rating="averageRating"></Rating>
    <div
      data-cy="review-average"
      class="review-aggregate__summary u-margin-top--0-5 sm:u-margin-top--0"
    >
      <span class="u-font-weight--bold">{{ averageRating }}</span>
      out of 5 based on {{ reviewsCount }} reviews submitted.
      <Popper v-if="showInfo" :show-popper="showPopper" @hide="closePopper">
        <template slot="reference">
          <button
            class="review-aggregate__info"
            aria-label="Open"
            @click="openPopper"
          >
            <Icon name="alert-circle" flip="vertical"></Icon>
          </button>
        </template>
        <template slot="content">
          Reviews are from travellers who have completed their trip and want to
          share their experience. We show all reviews, but may remove any that
          include profanity or inappropriate content, are unrelated to the trip
          or reveal personal details.
        </template>
      </Popper>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import "atlas/src/components/Icon/icons/alert-circle.js";
import Popper from "atlas/src/components/Popper/Popper.vue";
import Rating from "atlas/src/components/Rating/Rating.vue";
import { ReviewAggregateProps } from "./Props";

export default Vue.extend({
  name: "ReviewAggregate",
  components: {
    Icon,
    Rating,
    Popper,
  },
  props: {
    averageRating: {
      type: Number as PropType<ReviewAggregateProps["averageRating"]>,
      required: true,
    },
    reviewsCount: {
      type: Number as PropType<ReviewAggregateProps["reviewsCount"]>,
      required: true,
    },
    showInfo: {
      type: Boolean as PropType<ReviewAggregateProps["showInfo"]>,
      required: false,
      default() {
        return true;
      },
    },
  },
  data(): {
    showPopper: boolean;
  } {
    return {
      showPopper: false,
    };
  },
  methods: {
    openPopper(): void {
      this.showPopper = true;
    },
    closePopper(): void {
      this.showPopper = false;
    },
  },
});
</script>
<style lang="scss">
@import "./review-aggregate";
</style>
